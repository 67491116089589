import { PropertyOnboardingFormTabbed } from "@global";
import QRCode from "./account/qrCode";
import React from "react";

const Code = () => {
  return (
    <>
      <QRCode
        url="https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=300x300&chld=M|0&cht=qr&chl=https://files.vaultre.com.au/cgi-bin/qrcheckin/checkin.cgi?id=eyJhbGciOiJIUzI1NiJ9.eyJpc2xlYXNlIjoiMCIsImxpZmVpZCI6IjE1OTQwOTE2IiwiYWNjb3VudGlkIjoiNDIxNCJ9.t5Nt_wW0U8Uh9E6ap7SE_gaWb8C_z4M6SHPAvTh6WHU"
        address="8 first avenue"
      />
    </>
  );
};

export default Code;
